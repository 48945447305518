import * as React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, Grid, InputLabel, Select } from "@mui/material";


export default function UnitMetaDataEditForm({unitRent, handleChangeUnitRent, rentCycle, handleChangeRentCycle}) {
	return (
		<>
			<Grid container component="form" noValidate autoComplete="off" spacing={2} sx={{marginTop:"1px"}}>
				<Grid item xs={12} sm={6}>
					<TextField size="small" fullWidth value={unitRent} label="Unit Rent" onChange={handleChangeUnitRent} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth size="small">
						<InputLabel id="demo-simple-select-label">Unit Rent Cycle</InputLabel>
						<Select label="Unit Rent cycle" labelId="rental-type" id="rental-type-id" defaultValue={rentCycle} onChange={handleChangeRentCycle}>
                            <MenuItem value="MONTHLY">MONTHLY</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			</Grid>
		</>
	);
}