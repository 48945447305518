import * as React from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Box, Stack, Typography } from "@mui/material";
import moment from "moment";
import ArticleIcon from "@mui/icons-material/Article";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

export default function TicketView({details}) {
	return (
		<Stack spacing={3}>
			<Box sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
				<CalendarTodayIcon />
				<strong>&nbsp;Date Logged:&nbsp;</strong>
				<Typography variant="h5">{moment(details.date_created).format("Do MMMM YYYY")}</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
				<PersonIcon />
				<strong>&nbsp;Logged by:&nbsp;</strong>
				<Typography variant="h5">
					{details.related_tenant.first_name} {details.related_tenant.last_name}
				</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
				<PhoneIcon />
				<strong>&nbsp;Tenant Contact:&nbsp;</strong>
				<Typography variant="h5">0{details.related_tenant.phone_number}</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
				<EmailIcon />
				<strong>&nbsp;Tenant Email:&nbsp;</strong>
				<Typography variant="h5">{details.related_tenant.email}</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
				<ArticleIcon />
				<strong>&nbsp;Description:&nbsp;</strong>
				<Typography variant="h5">{details.description}</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
				<OtherHousesIcon />
				<strong>&nbsp;Unit Name:&nbsp;</strong>
				<Typography variant="h5">{details.related_rental_unit.unit_name}</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
				<HolidayVillageIcon />
				<strong>&nbsp;Unit type:&nbsp;</strong>
				<Typography variant="h5">{details.related_rental_unit.unit_type}</Typography>
			</Box>
		</Stack>
	);
}
