import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import PaymentIcon from '@mui/icons-material/Payment';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import NoteIcon from "@mui/icons-material/Note";
import MoneyIcon from '@mui/icons-material/Money';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
			<MenuItem
				active={selected === title}
				style={{
					color: colors.primary[10],
					marginBottom: "10px",
				}}
				onClick={() => setSelected(title)}
				icon={icon}
			>
				<Typography sx={{ fontSize: { xs: "16px", md: "20px" } }} fontWeight={600}>
					{title}
				</Typography>
				<Link to={to} />
			</MenuItem>
		);
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("Dashboard");

  return (
			<Box
				sx={{
					// position: "fixed",
					// display: "flex",
					// height: "100vh",
					// top: 72,
					// bottom: 0,
					// zIndex: 10000,
					"& .pro-sidebar-inner": {
						background: `${colors.primary[10]} !important`,
					},
					"& .pro-icon-wrapper": {
						backgroundColor: "transparent !important",
					},
					"& .pro-inner-item": {
						padding: { xs: "5px 15px 5px 10px !important", sm: "5px 35px 5px 20px !important" },
					},
					"& .pro-menu-item": {
						color: "#333 !important",
					},
					"& .pro-inner-item:hover": {
						color: "#FCB200 !important",
					},
					"& .pro-menu-item.active": {
						color: "#FCB200 !important",
					},
					borderRight: "1px solid #dfdede;",
				}}
			>
				<ProSidebar collapsed={isCollapsed}>
					<Menu iconShape="square">
						{/* LOGO AND MENU ICON */}
						<MenuItem
							onClick={() => setIsCollapsed(!isCollapsed)}
							icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
							style={{
								margin: "10px 0 20px 0",
								color: colors.grey[10],
							}}
						>
							{!isCollapsed && (
								<Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
									<Typography variant="h4" color={colors.grey[10]}>
										LANDLORD
									</Typography>
									<IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
										<MenuOutlinedIcon sx={{ color: colors.grey[10] }} />
									</IconButton>
								</Box>
							)}
						</MenuItem>

						<Box paddingLeft={isCollapsed ? undefined : "1%"}>
							<Item title="Dashboard" to="/" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />
							<Item title="Tenants" to="/tenants" icon={<PeopleOutlinedIcon />} selected={selected} setSelected={setSelected} />
							<Item title="Payments" to="/payments" icon={<PaymentIcon />} selected={selected} setSelected={setSelected} />
							<Item title="Invoices" to="/invoices" icon={<MoneyIcon />} selected={selected} setSelected={setSelected} />
							<Item title="Expenses" to="/expenses" icon={<ShoppingBagIcon />} selected={selected} setSelected={setSelected} />
							<Item title="Payouts" to="/payments/landlord" icon={<LocalAtmIcon />} selected={selected} setSelected={setSelected} />
							<Item title="Properties" to="/properties" icon={<ApartmentIcon />} selected={selected} setSelected={setSelected} />
							<Item title="Tickets" to="/tickets" icon={<NoteIcon />} selected={selected} setSelected={setSelected} />
							<Item title="Reports" to="/reports" icon={<AssessmentIcon />} selected={selected} setSelected={setSelected} />
							{/* <Item title="Messages" to="/home" icon={<MailIcon />} selected={selected} setSelected={setSelected} /> */}
							<Item title="Settings" to="/settings" icon={<SettingsIcon />} selected={selected} setSelected={setSelected} />
						</Box>
					</Menu>
				</ProSidebar>
			</Box>
		);
};

export default Sidebar;