import * as React from "react";
import { Stack, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Box from "@mui/material/Box";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useLocation } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { IoMdEyeOff } from "react-icons/io";
import { formatDate, formatDateString } from "utilities/date";
import { currencyFormatter } from "utilities/currencyFormatter";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#f1f1f5",
		color: "#000",
		fontSize: 15,
		fontWeight: 700,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 16,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: "white",
	},
	// // hide last border
	// "&:last-child td, &:last-child th": {
	// 	border: 0,
	// },
}));


function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

export default function TenantPaymentsTable(props) {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [showDetails, setShowDetails] = React.useState(false);
	const [activeID, setActiveID] = React.useState(null);
	const location = useLocation();

	const renderStatusPills = (status) => {
		switch (status) {
			case false:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffebeb", fontSize:"10px", fontWeight:700 }} label="INCOMPLETE" size="small" color="warning" />;
			case true:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ebefff", fontSize:"10px", fontWeight:700 }} label="COMPLETE" size="small" color="primary" />;
		}
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const toggleDetailsView = (rowId) => {
		setActiveID(rowId);
		setShowDetails(!showDetails);
	};

	return (
		<>
			<TableContainer sx={{ display: { xs: "none", md: "block" } }} component={Paper}>
				<Table sx={{ minWidth: 700 }} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Amount Paid</StyledTableCell>
							<StyledTableCell align="left">Period Paid For</StyledTableCell>
							<StyledTableCell align="left">Month Start Date</StyledTableCell>
							<StyledTableCell align="left">Month End Date</StyledTableCell>
							<StyledTableCell align="left">Is Full Payment</StyledTableCell>
							<StyledTableCell align="left">Actions</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
                        {(rowsPerPage > 0 ? props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : props.rows).map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                    {currencyFormatter(parseInt(row.total))}
                                </StyledTableCell>
                                <StyledTableCell align="left">{row.related_month.month} {row.related_month.year}</StyledTableCell>
                                <StyledTableCell align="left">{formatDateString(row.date_started)}</StyledTableCell>
                                <StyledTableCell align="left">{formatDateString(row.date_ended)}</StyledTableCell>
                                <StyledTableCell align="left">{renderStatusPills(row.is_completed)}</StyledTableCell>
                                <StyledTableCell align="left">
									<IconButton color="primary" size="small" aria-describedby={props.id}>
										<MoreHorizIcon />
									</IconButton>
								</StyledTableCell>
                            </StyledTableRow>
                        ))}
					</TableBody>
				</Table>
				<TablePagination
					component="div"
					colSpan={12}
					count={props.rows.length}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Rows per page" // Custom label for "Rows per page"
					sx={{
						// Styles for the TablePagination component itself
						"& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input.css-baf1rs-MuiInputBase-root-MuiTablePagination-select": {
							marginRight: "5px",
						},
						"& .MuiTablePagination-actions": {
							marginLeft: 0,
						},
						"& .MuiTablePagination-displayedRows": {
							fontSize: "1rem",
							margin: 0,
						},
						"& .MuiSelect-select.MuiInputBase-input": {
							fontSize: "1rem",
							marginTop: "10px",
						},
						"& .MuiTablePagination-selectLabel": {
							fontSize: "1rem",
						},
						"& .MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select": {
							marginTop: "4px",
						},
					}}
				/>
			</TableContainer>
		</>
	);
}
