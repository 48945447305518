import React, {useEffect, useState} from "react";
import { Box, Stack, Typography, Card, CardContent, Button, Divider } from "@mui/material";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import { FlexStart, FlexEnd, FlexCenter } from "components/FlexBox/FlexBox";
import ImageGallery from 'components/Images/ImageGallery';
import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import FitnessCenterOutlinedIcon from "@mui/icons-material/FitnessCenterOutlined";
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import ElevatorIcon from '@mui/icons-material/Elevator';
import PoolIcon from '@mui/icons-material/Pool';
import SendIcon from '@mui/icons-material/Send';
import HouseIcon from '@mui/icons-material/House';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import NearMeIcon from '@mui/icons-material/NearMe';
import PlaceIcon from '@mui/icons-material/Place';
import ImageUploader from "components/Images/ImageUploader";
import EditIcon from '@mui/icons-material/Edit';
import FlexBetween from "components/FlexBetween";
import FormDialog from "components/modal";
import RentalsEditForm from "components/Forms/RentalsEditForm";
import axios from "axios";
import { baseURL } from "services/API";
import { useSelector } from "react-redux";
import { dummyImages } from "utilities/dummyImages";
import TopSnack from "components/TopSnack";


const facilityIcons = {
	marginRight: "8px",
	fontSize: "20px",
	color: "#52524e",
};

const coloredIconGreen = {
	marginRight: "8px",
	fontSize: "20px",
	color: "#0af047",
};

const coloredIconRed = {
	marginRight: "8px",
	fontSize: "20px",
	color: "#f20a0a",
};

const detailsText = {
	fontSize: "16px",
	fontWeight: 600,
	padding: "5px"
}

const descriptionText = {
	fontSize: "15px",
	fontWeight: 400,
	fontStyle: "italic"
}

const PropertyDetails = () => {
  const location = useLocation();
  const token = useSelector((state) => state.token)
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const { id } = location.state;
  const [tempRef, setTempRef] = useState(0)
  const [open, setOpen] = React.useState(false);

  const [propertyDetails, setPropertyDetails] = useState({})
  const [images, setImages] = useState([])
  const [defaultImages, setDefaultImages] = useState(true) 

  const [submittingFiles, setSubmittingFiles] = useState(false)
  const [files, setFiles] = useState([]);

  const [loadingPropertyDetails, setLoadingPropertyDetails] = useState(true)
  const [rentalName, setRentalName] = useState("")
  const [propertyUse, setPropertyUse] = useState("")
  const [propertyLocation, setPropertyLocation] = useState("")
  const [locationCounty, setLocationCounty] = useState("")
  const [security, setSecurity] = useState("")
  const [hospitalAccess, setHospitalAccess] = useState("")
  const [roadAccess, setRoadAccess] = useState("")
  const [parking, setParking] = useState("")
  const [fibre, setFibre] = useState("")
  const [elevator, setElevator] = useState("")
  const [gym, setGym] = useState("")
  const [pool, setPool] = useState("")


  const fetchDetails = async () => {
	try {
		const response = await axios.get(`${baseURL}/landlords/rentals/details?rental_id=${id}`);
		setPropertyDetails(response.data.data);
		setRentalName(response.data.data.rental_name)
		setPropertyUse(response.data.data.rental_type)
		setPropertyLocation(response.data.data.location)
		setLocationCounty(response.data.data.location_county)
		setSecurity(response.data.data.has24_security)
		setHospitalAccess(response.data.data.hospital_access)
		setRoadAccess(response.data.data.main_road_access)
		setParking(response.data.data.has_parking)
		setFibre(response.data.data.has_fibre)
		setElevator(response.data.data.has_elevator)
		setGym(response.data.data.has_gym)
		setPool(response.data.data.has_swimming_pool)
		setLoadingPropertyDetails(false);
	} catch (e) {
		setPropertyDetails(true);
		setLoadingPropertyDetails(false);
	}
  } 

  const fetchImages = async () => {
	try {
		const response = await axios.get(`${baseURL}/landlords/rentals/images?rental_id=${id}`);
		console.log(response.data.data.length)
		if(response.data.data.length > 0) {
			setImages(response.data.data)
			setDefaultImages(false)
		} else {
			setImages(response.data.data)
			setDefaultImages(true)
		}
		setLoadingPropertyDetails(false);
	} catch (e) {
		setLoadingPropertyDetails(false);
	}
  } 

  useEffect(() => {
	fetchDetails()
	fetchImages()
  }, [tempRef])

  const handleClickOpen = () => {
	setOpen(true);
  };

  const handleClose = () => {
	setOpen(false);
  };

  const handleChangeRentalName = (event) => {
	setRentalName(event.target.value);
  };

  const handleChangePropertyUse = (event) => {
	setPropertyUse(event.target.value);
  };

  const handleChangeLocation = (event) => {
	setPropertyLocation(event.target.value);
  };

  const handleChangeLocationCounty = (event) => {
	setLocationCounty(event.target.value);
  };

  const handleChangeSecurity = (event) => {
	setSecurity(event.target.checked);
  };

  const handleChangeHospitalAccess = (event) => {
	setHospitalAccess(event.target.checked);
  };

  const handleChangeRoadAccess = (event) => {
	setRoadAccess(event.target.checked);
  };

  const handleChangeParking = (event) => {
	setParking(event.target.checked);
  };

  const handleChangeFibre = (event) => {
	setFibre(event.target.checked);
  };

  const handleChangeElevator = (event) => {
	setElevator(event.target.checked);
  };

  const handleChangeGym = (event) => {
	setGym(event.target.checked);
  };

  const handleChangePool = (event) => {
	setPool(event.target.checked);
  };

  const handleRentalEditFormSubmit = async () => {
	try {
		await axios.post(`${baseURL}/landlords/rentals/details?rental_id=${id}`, {
			rental_name: rentalName,
			rental_type: propertyUse,
			location: propertyLocation,
			location_county: locationCounty,
			has24_security: security,
			has_gym: gym,
			has_swimming_pool: pool,
			hospital_access: hospitalAccess,
			main_road_access: roadAccess,
			has_elevator: elevator,
			has_fibre: fibre,
			has_parking: parking
		});
		setOpen(false);
		setRentalName("")
		setPropertyUse("")
		setPropertyLocation("")
		setSecurity("")
		setLocationCounty("")
		setHospitalAccess("")
		setRoadAccess("")
		setParking("")
		setFibre("")
		setElevator("")
		setGym("")
		setPool("")
		setTempRef(tempRef+1)
	} catch (err) {
		console.log(err);
	}
 };

 const handleRentalFileUploadSubmit = async (file) => {
	try {
		const formData = new FormData();
		formData.append("image", file);
		formData.append("related_rental", id);
		for (const key of formData.entries()) {
			console.log(key[0] + ', ' + key[1]);
		}
		await axios.post(`${baseURL}/landlords/rentals/images`, formData);
		
	} catch (err) {
		console.log(err);
	}
 }; 

 const submitFiles = () => {
	setSubmittingFiles(true)
	files.map(async (file) => {
		await handleRentalFileUploadSubmit (file)
	})
	setSubmittingFiles(false)
	setTempRef(tempRef+1)
	setFiles([])
 }


  return (
    <Box m="50px" p="30px">
		<Typography variant="h3">Property Name : {rentalName}</Typography>
		<Divider />
        <Grid container spacing={2} marginTop="30px">
				<Grid xs={12} sm={7}>
					<Stack spacing={3} sx={{ marginBottom: "20px" }}>
						{defaultImages ? (
							<ImageGallery imageList={dummyImages} defaultImages={defaultImages}/>
						) : (
							<ImageGallery imageList={images} defaultImages={defaultImages}/>
						)}
						
						<FlexBetween>
							<Typography variant="h3">Property Details</Typography>
							
							<FlexEnd onClick={handleClickOpen} sx={{
								cursor: "pointer"
							}}>
								<Typography variant="h5" sx={{color:"#0759e8"}}>Edit Details</Typography>
								<EditIcon sx={facilityIcons} />
							</FlexEnd>
						</FlexBetween>
						
						<Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px", padding: "1rem" }}>
							<Grid container spacing={2}>
								<Grid xs={12} sm={12}>
									<Stack spacing={2}>
										<FlexCenter>
											<HouseIcon sx={facilityIcons} />
											<Typography sx={descriptionText}>Property Name: </Typography>
											<Typography sx={detailsText}>{rentalName}</Typography>
										</FlexCenter>
										<FlexCenter>
											<FormatPaintIcon sx={facilityIcons} />
											<Typography sx={descriptionText}>Property Type: </Typography>
											<Typography sx={detailsText}>{propertyUse}</Typography>
										</FlexCenter>
										<FlexCenter>
											<NearMeIcon sx={facilityIcons} />
											<Typography sx={descriptionText}>Property Region: </Typography>
											<Typography sx={detailsText}>{propertyLocation}</Typography>
										</FlexCenter>
										<FlexCenter>
											<PlaceIcon sx={facilityIcons} />
											<Typography sx={descriptionText}>Property Location: </Typography>
											<Typography sx={detailsText}>{locationCounty}</Typography>
										</FlexCenter>
									</Stack>
								</Grid>
							</Grid>
						</Box>

						<Typography variant="h4">Facilities</Typography>
						<Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px", padding: "1rem" }}>
							<Grid container spacing={2}>
								<Grid xs={12} sm={6}>
									<Stack spacing={2}>
										<FlexCenter>
											<LockIcon sx={facilityIcons} />
											<Typography>Has 24 Hour Security?</Typography>
											{propertyDetails.has24_security ? (							<CheckCircleIcon sx={coloredIconGreen} />
											) : (
												<ClearIcon sx={coloredIconRed} />
											)}
											
										</FlexCenter>
										<FlexCenter>
											<FitnessCenterOutlinedIcon sx={facilityIcons} />
											<Typography>Has a Gym?</Typography>
											{propertyDetails.has_gym ? (										<CheckCircleIcon sx={coloredIconGreen} />
											) : (
												<ClearIcon sx={coloredIconRed} />
											)}
										</FlexCenter>
										<FlexCenter>
											<LocalPharmacyIcon sx={facilityIcons} />
											<Typography>Hospital Access in less than 10 Minutes</Typography>
											{propertyDetails.hospital_access ? (										<CheckCircleIcon sx={coloredIconGreen} />
											) : (
												<ClearIcon sx={coloredIconRed} />
											)}
										</FlexCenter>
										<FlexCenter>
											<AddRoadIcon sx={facilityIcons} />
											<Typography>Main Road Access in less than 5 minutes</Typography>
											{propertyDetails.main_road_access ? (												<CheckCircleIcon sx={coloredIconGreen} />
											) : (
												<ClearIcon sx={coloredIconRed} />
											)}
										</FlexCenter>
									</Stack>
								</Grid>
								<Grid xs={12} sm={6}>
									<Stack spacing={2}>
										<FlexCenter>
											<DirectionsCarFilledOutlinedIcon sx={facilityIcons} />
											<Typography>Has Ample Parking Space</Typography>
											{propertyDetails.has_parking ? (												<CheckCircleIcon sx={coloredIconGreen} />
											) : (
												<ClearIcon sx={coloredIconRed} />
											)}
										</FlexCenter>
										<FlexCenter>
											<WifiOutlinedIcon sx={facilityIcons} />
											<Typography>Has Fibre/Wifi</Typography>
											{propertyDetails.has_fibre ? (												<CheckCircleIcon sx={coloredIconGreen} />
											) : (
												<ClearIcon sx={coloredIconRed} />
											)}
										</FlexCenter>
										<FlexCenter>
											<PoolIcon sx={facilityIcons} />
											<Typography>Has Swimming Pool</Typography>
											{propertyDetails.has_swimming_pool ? (												<CheckCircleIcon sx={coloredIconGreen} />
											) : (
												<ClearIcon sx={coloredIconRed} />
											)}
										</FlexCenter>
										<FlexCenter>
											<ElevatorIcon sx={facilityIcons} />
											<Typography>Has Elevator</Typography>
											{propertyDetails.has_elevator ? (												<CheckCircleIcon sx={coloredIconGreen} />
											) : (
												<ClearIcon sx={coloredIconRed} />
											)}
										</FlexCenter>
									</Stack>
								</Grid>
							</Grid>
						</Box>
					</Stack>
				</Grid>
				
				<Grid sx={{ display: { xs: "none", sm: "block" } }} xs={12} sm={5}>
					<Card sx={{ position: "sticky", top: 0 }}>
						<CardContent sx={{ padding: 0 }}>
							<FlexStart sx={{ padding: "16px" }}>
							{/* <LandLordAvatar /> */}
								<Box sx={{ marginLeft: "8px" }}>
									<Typography variant="body1" sx={{ fontSize: "17px" }}>
										Add Property Images
									</Typography>
								</Box>
							</FlexStart>
							<Divider />
							<Stack spacing={3} sx={{ padding: "16px" }}>
								<ImageUploader files={files} setFiles={setFiles} maxFiles={3}/>
							</Stack>
							<Stack spacing={3} sx={{ padding: "16px" }}>
								{files.length > 0 && (
									<>
									{submittingFiles ? (
										<Button variant="outlined" loading>Submitting Images for Review</Button>
									) : (
										<Button variant="outlined" endIcon={<SendIcon />} onClick={submitFiles}>Submit Images for Review</Button>
									)}
									</>
								)}
							</Stack>
						</CardContent>
					</Card>
				</Grid>
		</Grid>
		<FormDialog
			open={open}
			handleClose={handleClose}
			enableActions={true}
			content={
				<RentalsEditForm
					rentalName={rentalName}
					handleChangeRentalName={handleChangeRentalName}
					propertyUse={propertyUse}
					handleChangePropertyUse={handleChangePropertyUse}
					location={propertyLocation}
					handleChangeLocation={handleChangeLocation}
					locationCounty={locationCounty}
					handleChangeLocationCounty={handleChangeLocationCounty}
					security={security}
					handleChangeSecurity={handleChangeSecurity}
					hospitalAccess={hospitalAccess}
					handleChangeHospitalAccess={handleChangeHospitalAccess}
					roadAccess={roadAccess}
					handleChangeRoadAccess={handleChangeRoadAccess}
					parking={parking}
					handleChangeParking={handleChangeParking}
					fibre={fibre}
					handleChangeFibre={handleChangeFibre}
					elevator={elevator}
					handleChangeElevator={handleChangeElevator}
					gym={gym}
					handleChangeGym={handleChangeGym}
					pool={pool}
					handleChangePool={handleChangePool}
				/>
			}
			title={"Edit Property Details"}
			handleSubmit={handleRentalEditFormSubmit}
			buttonText="submit"
		/>
    </Box>
  );
};

export default PropertyDetails;