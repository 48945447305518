import * as React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, Grid, InputLabel, Select } from "@mui/material";
import { FlexRowCenter } from "components/FlexBox/FlexBox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function UnitDetailsEditForm({unitName, handleChangeUnitName, unitType, handleChangeUnitType, bedrooms, handleChangeBedrooms, bathrooms, handleChangeBathrooms, dstv, handleChangeDstv, fibre, handleChangeFibre, funished, handleChangeFurnished, insideBathroom, handleChangeInsideBathroom, insideKitchen, handleChangeInsideKitchen, store, handleChangeStore }) {
	return (
		<>
			<Grid container component="form" noValidate autoComplete="off" spacing={2} sx={{marginTop:"1px"}}>
				<Grid item xs={12} sm={6}>
					<TextField size="small" fullWidth value={unitName} label="Unit Name" onChange={handleChangeUnitName} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth size="small">
						<InputLabel id="demo-simple-select-label">Unit Type</InputLabel>
						<Select label="Unit Type" labelId="rental-type" id="rental-type-id" defaultValue={unitType} onChange={handleChangeUnitType}>
                            <MenuItem value="REGULAR">REGULAR</MenuItem>
							<MenuItem value="CONDOMINIUM">CONDOMINIUM</MenuItem>
							<MenuItem value="SEMI DETACHED">SEMI DETACHED</MenuItem>
						</Select>
					</FormControl>
				</Grid>
                <Grid item xs={12} sm={12}>
                    <FlexRowCenter>
                        <FormControl sx={{marginY: "10px", marginX: "5px"}} fullWidth size="medium">
                            <InputLabel id="demo-simple-select-label">Number of Bedrooms</InputLabel>
                            <Select
                                label="Number of Bedrooms"
                                labelId="bedrooms"
                                value={bedrooms}
                                onChange={handleChangeBedrooms}
                            >
                                <MenuItem value="0">0</MenuItem>
                                <MenuItem value="1">1</MenuItem>
                                <MenuItem value="2">2</MenuItem>
                                <MenuItem value="3">3</MenuItem>
                                <MenuItem value="4">4</MenuItem>
                                <MenuItem value="5">5</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{marginY: "10px", marginX: "5px"}} fullWidth size="medium">
                            <InputLabel id="demo-simple-select-label">Number of Bathrooms</InputLabel>
                            <Select
                                label="Number of Bathrooms"
                                labelId="bathrooms"
                                value={bathrooms}
                                onChange={handleChangeBathrooms}
                            >
                                <MenuItem value="0">0</MenuItem>
                                <MenuItem value="1">1</MenuItem>
                                <MenuItem value="2">2</MenuItem>
                                <MenuItem value="3">3</MenuItem>
                                <MenuItem value="4">4</MenuItem>
                                <MenuItem value="5">5</MenuItem>
                            </Select>
                        </FormControl>
                    </FlexRowCenter>
                </Grid>
				<Grid item xs={12} sm={5} spacing={2}>
					<FormGroup>
						<FormControlLabel control={<Checkbox checked={insideKitchen} onChange={handleChangeInsideKitchen} />} label="Has inside Kitchen?" />
                        <FormControlLabel control={<Checkbox checked={insideBathroom} onChange={handleChangeInsideBathroom}/>} label="Has Inside Bathroom?" />
						<FormControlLabel control={<Checkbox checked={dstv} onChange={handleChangeDstv}/>} label="Has Dstv Connection?" />
                        <FormControlLabel control={<Checkbox checked={fibre} onChange={handleChangeFibre}/>} label="Has Fibre Connection?" />
					</FormGroup>
				</Grid>
				<Grid item xs={12} sm={7} spacing={2}>
					<FormGroup>
                        <FormControlLabel control={<Checkbox checked={funished} onChange={handleChangeFurnished}/>} label="Is Fully Furnished?" />
						<FormControlLabel control={<Checkbox checked={store} onChange={handleChangeStore}/>} label="Has Store?" />
					</FormGroup>
				</Grid>
			</Grid>
		</>
	);
}