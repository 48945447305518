import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

export default function BasicCard({ name, value, color,bgcolor, icon, link }) {
	return (
		<Link to={link}>
			<Card sx={{ borderLeft: `solid 4px ${color}` }}>
				<CardContent sx={{ paddingBottom: "10px !important" }}>
					<Typography sx={{ fontWeight: 800 }} variant="h6" component="div">
						{name}
					</Typography>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							marginTop: "20px",
						}}
					>
						<Typography variant="h2" sx={{ fontWeight: 800, fontSize: "26px" }}>
							{value}
						</Typography>
						<Typography sx={{ color: `${color}`, padding: "10px 10px 0 10px", borderRadius: "12px", backgroundColor: `${bgcolor}` }} variant="h2">
							{icon}
						</Typography>
					</div>
				</CardContent>
			</Card>
		</Link>
	);
}
