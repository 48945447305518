import * as React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, Grid, InputLabel, Select } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function RentalsForm(props) {
	return (
		<>
			<Grid container component="form" noValidate autoComplete="off" spacing={2} sx={{marginTop:"1px"}}>
				<Grid item xs={12} sm={6}>
					<TextField size="small" fullWidth label="Property Name" onChange={props.handleRentalNameChange} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth size="small">
						<InputLabel id="demo-simple-select-label">Property Use Type</InputLabel>
						<Select label="Property Use Type" labelId="rental-type" id="rental-type-id" value={props.rentalType} onChange={props.handleChangeRentalType}>
							<MenuItem value="MIXED USE">Mixed Use</MenuItem>
							<MenuItem value="RESIDENTIAL">Residential</MenuItem>
							<MenuItem value="COMMERCIAL">Commercial</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth size="small">
						<InputLabel id="demo-simple-select-label">Property Location (City)</InputLabel>
						<Select
							label="Property Location (City)"
							labelId="rental-type"
							id="rental-type-id"
							value={props.rentalCity}
							onChange={props.handleRentalCityChange}
						>
							<MenuItem value="KAMPALA">Kampala</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField size="small" fullWidth label="Property Location (Parish & County)" onChange={props.handleRentalLocationChange} />
				</Grid>
				<Grid item xs={12} sm={5} spacing={2}>
					<FormGroup>
						<FormControlLabel control={<Checkbox checked={props.security} onChange={props.handleSecurityCheck} />} label="Has 24 Hour Security?" />
						<FormControlLabel control={<Checkbox />} label="Has Gym?" />
						<FormControlLabel control={<Checkbox />} label="Has Swimming Pool?" />
					</FormGroup>
				</Grid>
				<Grid item xs={12} sm={7} spacing={2}>
					<FormGroup>
						<FormControlLabel control={<Checkbox />} label="Has Elevator?" />
						<FormControlLabel control={<Checkbox />} label="Hospital Access in less than 10 minutes" />
						<FormControlLabel control={<Checkbox />} label="Main Road Access in less than 5 minutes" />
					</FormGroup>
				</Grid>
			</Grid>
		</>
	);
}
