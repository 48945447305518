import React, {useState} from "react";
import { Grid, Box } from "@mui/material";
import { formatDate } from "utilities/date";
import FormDialog from "components/modal";
import SettingsPill from "components/SettingsPill";
import DeleteAccountView from "components/MiniViews/DeleteAccountView";
import ChangePasswordForm from "components/Forms/AccountSettingForms/ChangePasswordForm";
import { Divider } from "@mui/material";

export default function UserAccountSettings() {
	const [openPassword, setOpenPassword] = useState(false);
    const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
    const [openArchiveData, setOpenArchiveData] = useState(false);
    const [openChangeVisibility, setOpenChangeVisibility] = useState(false);
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
	const [details, setDetails] = useState("");
	const [modal, setModal] = useState("");
	const [anchorEl, setAnchorEl] = useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;

    const handleClickOpenPassword = () => {
		setOpenPassword(true);
	};

	const handleClosePassword = () => {
		setModal("");
		setOpenPassword(false);
	};

    const handleClickOpenDeleteAccount = () => {
		setOpenDeleteAccount(true);
	};

	const handleCloseDeleteAccount = () => {
		setModal("");
		setOpenDeleteAccount(false);
	};

    const handleClickOpenArchiveData = () => {
		setOpenArchiveData(true);
	};

	const handleCloseArchiveData = () => {
		setModal("");
		setOpenArchiveData(false);
	};

    const handleClickOpenChangeVisibility = () => {
		setOpenChangeVisibility(true);
	};

	const handleCloseChangeVisibility = () => {
		setModal("");
		setOpenChangeVisibility(false);
	};

    const handleChangeNewPassword = (event) => {
		setNewPassword(event.target.value);
	};

	const handleChangeConfirmPassword = (event) => {
		setConfirmPassword(event.target.value);
	};

    const handleSubmitChangePassword = () => {
        
    }


	return (
		<Box m="20px">
			<Grid container spacing={2}>
				<Grid item xs={12}>
                    <h1>User Account Settings</h1>
                    <h3>Account Security</h3>
                    <Box sx={{border: "1px solid black", padding: "10px"}}>
                        <SettingsPill pillTitle={"Change Account Password"} pillDescription={"Change your account's password"} buttonText={"Change Password"} buttonAction={handleClickOpenPassword}/>
                        <Divider />
                    </Box>

                    <h3>Payment Details</h3>
                    <Box sx={{border: "1px solid black", padding: "10px"}}>
                        <SettingsPill pillTitle={"Bank Payment Details"} pillDescription={"Attach your Bank Payment/Settlement Details"} buttonText={"Attach Bank Details"}/>
                        <Divider />
                        <SettingsPill pillTitle={"Mobile Money Payment Details"} pillDescription={"Attach your Mobile Money payment details"} buttonText={"Attach Mobile Payment Details"}/>
                        <Divider />
                    </Box>

                    <h3>Danger zone (The actions below are irreversible)</h3>
                    <Box sx={{border: "1px solid red", padding: "10px"}}>
                        <SettingsPill pillTitle={"Change Visibility"} pillDescription={"Make your entire account private (RentBeta administrators will not be able to see your property details)"} buttonText={"Change Visibility"} buttonColor={"error"} buttonAction={handleClickOpenChangeVisibility}/>
                        <Divider />
                        <SettingsPill pillTitle={"Archive Account Data"} pillDescription={"Archive your account data (This doesnt delete your account but it stores your data in a safe vault where you can always restore it from and continue operations)"} buttonText={"Archive Data"} buttonColor={"error"} buttonAction={handleClickOpenArchiveData}/>
                        <Divider />
                        <SettingsPill pillTitle={"Delete Account"} pillDescription={"Delete your account and all the data attached to it"} buttonText={"Delete Account"} buttonColor={"error"} buttonAction={handleClickOpenDeleteAccount}/>
                        <Divider />
                    </Box>
				</Grid>
			</Grid>
            <FormDialog
                open={openPassword}
                handleClose={handleClosePassword}
                title={"Change your Password"}
                handleSubmit={handleSubmitChangePassword}
                buttonText="Confirm Change Password"
                enableActions={true}
                buttonClass={"add-button"}
                content={
                    <ChangePasswordForm
                        handleChangePassword={handleChangeNewPassword}
                        handleChangeConfirmPassword={handleChangeConfirmPassword}
                    />
                }
            />

            <FormDialog
                open={openChangeVisibility}
                handleClose={handleCloseChangeVisibility}
                title={"Are you sure you want to hide your account data from RentBeta Admins?"}
                handleSubmit={handleSubmitChangePassword}
                buttonText="Confirm Hide Account"
                enableActions={true}
                buttonClass={"delete-button"}
                content={
                    <DeleteAccountView
                        text={"Please note that this action is not reversible. It will delete all your data and you will not be able to recover it"}
                    />
                }
            />   

            <FormDialog
                open={openArchiveData}
                handleClose={handleCloseArchiveData}
                title={"Are you sure you want to archive your data?"}
                handleSubmit={handleSubmitChangePassword}
                buttonText="Confirm Archive Account Data"
                enableActions={true}
                buttonClass={"delete-button"}
                content={
                    <DeleteAccountView
                        text={"Please note that this action is not reversible. It will delete all your data and you will not be able to recover it"}
                    />
                }
            />   

            <FormDialog
                open={openDeleteAccount}
                handleClose={handleCloseDeleteAccount}
                title={"Are you sure you want to delete your account?"}
                handleSubmit={handleSubmitChangePassword}
                buttonText="Confirm Delete Account"
                enableActions={true}
                buttonClass={"delete-button"}
                content={
                    <DeleteAccountView
                        text={"Please note that this action is not reversible. It will delete all your data and you will not be able to recover it"}
                    />
                }
            />            
		</Box>
	);
}
