import * as React from "react";
import { Stack, useTheme, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { formatDate, formatDateString } from "utilities/date";
import ProgressScale from "components/ProgressScale";
import DummyTable from "components/Tables/DummyTable";
import FormDialog from "components/modal";
import BasicSelect from "components/select";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TenantPaymentsTable from "components/Tables/TenantPaymentsTable";
import { useGetTenantOccupancyActivePeriodsData } from "services/hooks/rentals";


export default function TenantPayments(props) {
	const [rental, setRental] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [details, setDetails] = React.useState("");
	const [modal, setModal] = React.useState("");
	const occupancy = useSelector((state) => state.occupancy)
    const { activePeriods, isLoadingActivePeriods } = useGetTenantOccupancyActivePeriodsData();
    const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;

	const handleSelectChange = (event) => {
		setRental(event.target.value);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleModalView = (modalView) => {
		setModal(modalView);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleClickPopOver = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClosePopOver = () => {
		setAnchorEl(null);
	};

	return (
		<Box m="20px">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{isLoadingActivePeriods && <ProgressScale />}
					<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<h1 className="pageHeader">Tenant Payments</h1>
					</div>
					<Grid container spacing={2}>
					
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sx={{ marginTop: 3 }}>
							{isLoadingActivePeriods ? (
								<DummyTable />
							) : (
								<TenantPaymentsTable
									rows={activePeriods}
									handleClickPopOver={handleClickPopOver}
									id={popperId}
									formatDate={formatDate}
									setDetails={setDetails}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}
