import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NavAvatar from "./Avatars/NavAvatar";
import { useNavigate } from "react-router-dom";
import { setLogout } from "state";
import { useDispatch } from "react-redux";

export default function NavMenu({ name }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<span aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
				<NavAvatar name={name} />
			</span>

			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{ "aria-labelledby": "basic-button" }}
				sx={{
					marginTop: "1%",
					".MuiList-root.MuiMenu-list": {
						width: "140px",
					},
				}}
			>
				<MenuItem>My account</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						dispatch(setLogout());
						navigate("/");
					}}
				>
					Logout
				</MenuItem>
			</Menu>
		</div>
	);
}
