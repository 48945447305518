import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function TopSnack({open, handleCloseSnack, message, severity, vertical, horizontal}) {
  return (
			<Snackbar open={open} autoHideDuration={4000} onClose={handleCloseSnack} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
				<Alert onClose={handleCloseSnack} severity={severity} sx={{ width: "100%" }}>
					{message}
				</Alert>
			</Snackbar>
		);
}