import React, {useState} from 'react';
import { Grid, Box } from "@mui/material";
import ProgressScale from 'components/ProgressScale';
import BasicSelect from 'components/select';
import FormDialog from 'components/modal';
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import LandlordPaymentsTable from 'components/Tables/LandlordPaymentsTable';
import DummyTable from 'components/Tables/DummyTable';
import { useGetLandlordPaymentsData, useRentals, useGetMonthsData } from 'services/hooks/rentals';

export default function LandlordPayments() {
    const [rental, setRental] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const {paymentsData, setPaymentsData} = useState([])
    const {isLoadingPaymentsData, setLoadingPaymentsData} = useState(true)
    const { rentals } = useRentals()
	const {landlordPaymentsData, isLoadingLandlordPaymentsData, errorLoadingLandlordPaymentsData} = useGetLandlordPaymentsData()
	const {monthsData, isLoadingMonthsData, errorLoadingMonthsData} = useGetMonthsData()

    const handleSelectChange = (event) => {
        setRental(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };
 

    return (
			<Box m="20px">
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{isLoadingPaymentsData && <ProgressScale />}
						<h1>Landlord Payments</h1>
						<Grid container spacing={2}>
							<Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
								<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter By Rental"} data={rentals} />
							</Grid>
							<Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
								<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter By Month"} data={rentals} />
							</Grid>
							<Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
								<Button
								className="add-button"
								variant="contained"
								endIcon={<AddIcon />}
								>
									Request Payment
								</Button>
							</Grid>
							<Grid item xs={12} sm={5} md={8} sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "end", alignItems: "center" }}>
								
							</Grid>
							<Grid item sx={{ display: { xs: "block", sm: "none" } }} xs={12} sm={4}>
								<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter By Rental"} data={rentals} />
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12} sx={{ marginTop: 3 }}>
								{isLoadingLandlordPaymentsData ? 
                                    <DummyTable /> : 
                                    <LandlordPaymentsTable rows={landlordPaymentsData} />
                                }
							</Grid>
						</Grid>
						<FormDialog open={open} handleClose={handleClose} />
					</Grid>
				</Grid>
			</Box>
		);
}