import React, {useState, useEffect} from "react";
import { Box, Stack, Typography, Card, CardContent, Button, Divider, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Paper } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { FlexStart, FlexCenter, FlexRowCenter, FlexEnd } from "components/FlexBox/FlexBox";
import ImageUploader from "components/Images/ImageUploader";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import CountertopsOutlinedIcon from "@mui/icons-material/CountertopsOutlined";
import ShowerOutlinedIcon from "@mui/icons-material/ShowerOutlined";
import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import { baseURL } from "services/API";
import SendIcon from '@mui/icons-material/Send';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import FormDialog from "components/modal";
import axios from "axios";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import TopSnack from "components/TopSnack";
import FlexBetween from "components/FlexBetween";
import UnitDetailsEditForm from "components/Forms/UnitDetailsEditForm";
import UnitMetaDataEditForm from "components/Forms/UnitMetaDataEditForm";

const facilityIcons = {
	marginRight: "8px",
	fontSize: "20px",
	color: "#52524e",
};

const coloredIconGreen = {
	marginRight: "8px",
	fontSize: "20px",
	color: "#0af047",
};

const coloredIconRed = {
	marginRight: "8px",
	fontSize: "20px",
	color: "#f20a0a",
};

const detailsText = {
	fontSize: "16px",
	fontWeight: 600,
	padding: "5px"
}

const descriptionText = {
	fontSize: "15px",
	fontWeight: 400,
	fontStyle: "italic"
}

const formatNumberString = (numberString) => {
	const number = Number(numberString);
	if (isNaN(number)) {
		return "Invalid number string";
	}
	return number.toLocaleString();
};

const UnitDetails = () => {
	const location = useLocation();
  	const token = useSelector((state) => state.token)
  	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  	const { id } = location.state;

	const [files, setFiles] = useState([]);
	const [open, setOpen] = useState(false);
	const [openMeta, setOpenMeta] = useState(false);

	const [unitDetails, setUnitDetails] = useState({})
	const [unitTerms, setUnitTerms] = useState({})

	const [unitName, setUnitName] = useState("");
	const [rentCurrency, setRentCurrency] = useState("");
	const [rentCycle, setRentCycle] = useState("");
	const [unitType, setUnitType] = useState("");
	const [unitRent, setUnitRent] = useState("");
	const [bedrooms, setBedrooms] = useState("");
	const [bathrooms, setBathrooms] = useState("");
	const [insideKitchen, setInsideKitchen] = useState(false);
	const [insideBathroom, setInsideBathroom] = useState(false);
	const [dstv, setDstv] = useState(false);
	const [fibre, setFibre] = useState(false);
	const [funished, setFurnished] = useState(false);
	const [store, setStore] = useState(false);
	const [hasDownPayment, setHasDownPayment] = useState("");
	const [downPayment, setDownPayment] = useState("");
	const [hasSecurityFee, setHasSecurityFee] = useState("");
	const [securityFee, setSecurityFee] = useState("");
	const [tempRef, setTempRef] = useState(0)

	let unitData={}


	const fetchUnitDetails = async () => {
		try {
			const response = await axios.get(`${baseURL}/landlords/rentals/units/details?unit_id=${id}`);
			setUnitDetails(response.data.data);
			setUnitName(response.data.data.unit_name);
			setUnitType(response.data.data.unit_type)
			setBedrooms(response.data.data.number_of_bedrooms)
			setBathrooms(response.data.data.number_of_bathrooms)
			setInsideKitchen(response.data.data.has_inside_kitchen)
			setInsideBathroom(response.data.data.has_inside_bathroom)
			setDstv(response.data.data.has_dstv_connection)
			setFibre(response.data.data.has_fibre_connection)
			setStore(response.data.data.has_store)
			// setLoadingPropertyDetails(false);
		} catch (e) {
			// setLoadingPropertyDetails(false);
		}
	} 

	const fetchUnitTerms = async () => {
		try {
			const response = await axios.get(`${baseURL}/landlords/rentals/units/terms?unit_id=${id}`);
			setUnitTerms(response.data.data);
			setUnitRent(response.data.data.unit_rent)
			setRentCycle(response.data.data.unit_rent_cycle)
			setHasDownPayment(response.data.data.has_down_payment)
			setDownPayment(response.data.data.unit_cycle_down_payment)
			setHasSecurityFee(response.data.data.has_security_fee)
			setSecurityFee(response.data.data.unit_rent_security_fee)
			// setLoadingPropertyDetails(false);
		} catch (e) {
			// setLoadingPropertyDetails(false);
		}
	} 

	useEffect(() => {
		fetchUnitDetails()
		fetchUnitTerms()
	}, [tempRef])

	const handleClickOpen = () => {
		setOpen(true);
	};
	
	const handleClose = () => {
		setOpen(false);
	};

	const handleClickOpenMeta = () => {
		setOpenMeta(true);
	};
	
	const handleCloseMeta = () => {
		setOpenMeta(false);
	};

	const handleChangeUnitName = (event) => {
		setUnitName(event.target.value);
	};

	const handleChangeRentCurrency = (event) => {
		setRentCurrency(event.target.value);
	};

	const handleChangeRentCycle = (event) => {
		setRentCycle(event.target.value);
	};

	const handleChangeUnitType = (event) => {
		setUnitType(event.target.value);
	};

	const handleChangeUnitRent = (event) => {
		setUnitRent(event.target.value);
	};

	const handleChangeBedrooms = (event) => {
		setBedrooms(event.target.value);
	};

	const handleChangeBathrooms = (event) => {
		setBathrooms(event.target.value);
	};

	const handleChangeInsideBathroom = (event) => {
		setInsideBathroom(event.target.checked);
		unitData["has_inside_bathroom"] = insideBathroom
	};
	
	const handleChangeInsideKitchen = (event) => {
		setInsideKitchen(event.target.checked);
		unitData["has_inside_kitchen"] = insideKitchen
	};

	const handleChangeDstv = (event) => {
		setDstv(event.target.checked);
	};
	
	const handleChangeFibre = (event) => {
		setFibre(event.target.checked);
	};

	const handleChangeStore = (event) => {
		setStore(event.target.checked);
	};
	
	const handleChangeFurnished = (event) => {
		setFurnished(event.target.checked);
	};


	const handleRentalUnitMetaDataEditFormSubmit = async () => {
		try {
			await axios.post(`${baseURL}/landlords/rentals/units/terms?unit_id=${id}`, {
				unit_rent: unitRent,
				unit_rent_cycle: rentCycle,
			});
			setOpenMeta(false);
			setUnitRent("")
			setRentCycle("")
			setTempRef(tempRef+1)
		} catch (err) {
			console.log(err);
		}
	 };


  return (
    <Box m="50px" p="30px">
        <Grid container spacing={4}>
			<Grid xs={12} sm={6}>
				<Grid xs={12} sm={6}>
					<Stack spacing={3} sx={{ marginBottom: "20px" }}>
						<FlexBetween>
							<Typography variant="h4">Unit Details</Typography>
							<FlexEnd onClick={handleClickOpen} sx={{
								cursor: "pointer"
							}}>
								<Typography variant="h5" sx={{color:"#0759e8"}}>Edit Details</Typography>
								<EditIcon sx={facilityIcons} />
							</FlexEnd>
						</FlexBetween>
						<Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px", padding: "1rem" }}>
							<Grid container spacing={2} marginBottom={"15px"}>
								<Grid xs={12} sm={6}>
									<Stack spacing={2}>
										<FlexCenter>
											<ChairOutlinedIcon sx={facilityIcons} />
											<Typography sx={descriptionText}>Unit Name: </Typography>
											<Typography sx={detailsText}>{unitName}</Typography>
										</FlexCenter>
										<FlexCenter>
											<BedOutlinedIcon sx={facilityIcons} />
											<Typography sx={descriptionText}>Unit Type: </Typography>
											<Typography sx={detailsText}>{unitType}</Typography>
										</FlexCenter>
										<FlexCenter>
											<BedOutlinedIcon sx={facilityIcons} />
											<Typography sx={descriptionText}>No. of Bedrooms: </Typography>
											<Typography sx={detailsText}>{bedrooms}</Typography>
										</FlexCenter>
										<FlexCenter>
											<ShowerOutlinedIcon sx={facilityIcons} />
											<Typography sx={descriptionText}>No. of Bathrooms: </Typography>
											<Typography sx={detailsText}>{bathrooms}</Typography>
										</FlexCenter>
									</Stack>
								</Grid>
							</Grid>
							<Divider />
							<Typography variant="h5">Amenities (Select all that apply to the unit)</Typography>
							<Grid container spacing={2} marginTop={"10px"}>
								<Grid xs={12} sm={6}>
									<Stack spacing={2}>
										<FlexCenter>
											<ChairOutlinedIcon sx={facilityIcons} />
											<Typography>Has inside Kitchen?</Typography>
											{insideKitchen ? (	
												<CheckCircleIcon sx={coloredIconGreen} />
											) : (
												<ClearIcon sx={coloredIconRed} />
											)}
										</FlexCenter>
										<FlexCenter>
											<BedOutlinedIcon sx={facilityIcons} />
											<Typography>Has inside Bathroom?</Typography>
											{insideBathroom ? (	
												<CheckCircleIcon sx={coloredIconGreen} />
											) : (
												<ClearIcon sx={coloredIconRed} />
											)}
										</FlexCenter>
										<FlexCenter>
											<CountertopsOutlinedIcon sx={facilityIcons} />
											<Typography>Has Dstv Connection?</Typography>
											{dstv ? (	
												<CheckCircleIcon sx={coloredIconGreen} />
											) : (
												<ClearIcon sx={coloredIconRed} />
											)}
										</FlexCenter>
										<FlexCenter>
											<WifiOutlinedIcon sx={facilityIcons} />
											<Typography>Has Fibre Connection?</Typography>
											{fibre ? (	
												<CheckCircleIcon sx={coloredIconGreen} />
											) : (
												<ClearIcon sx={coloredIconRed} />
											)}
										</FlexCenter>
									</Stack>
								</Grid>
								<Grid xs={12} sm={6}>
									<Stack spacing={2}>
										<FlexCenter>
											<ChairOutlinedIcon sx={facilityIcons} />
											<Typography>Is fully furnished?</Typography>
											{funished ? (	
												<CheckCircleIcon sx={coloredIconGreen} />
											) : (
												<ClearIcon sx={coloredIconRed} />
											)}
										</FlexCenter>
										<FlexCenter>
											<ShowerOutlinedIcon sx={facilityIcons} />
											<Typography>Has a store?</Typography>
											{store ? (	
												<CheckCircleIcon sx={coloredIconGreen} />
											) : (
												<ClearIcon sx={coloredIconRed} />
											)}
										</FlexCenter>
									</Stack>
								</Grid>
							</Grid>
						</Box>
					</Stack>
				</Grid>

				<Grid xs={12} sm={6}>
					<Stack spacing={3} sx={{ marginBottom: "20px" }}>
						<FlexBetween>
							<Typography variant="h4">Unit Metadata Details</Typography>
							<FlexEnd onClick={handleClickOpenMeta} sx={{
								cursor: "pointer"
							}}>
								<Typography variant="h5" sx={{color:"#0759e8"}}>Edit Metadata</Typography>
								<EditIcon sx={facilityIcons} />
							</FlexEnd>
						</FlexBetween>
						<Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px", padding: "1rem" }}>
							<Grid container spacing={2} marginBottom={"15px"}>
								<Grid xs={12} sm={6}>
									<Stack spacing={2}>
										<FlexCenter>
											<ChairOutlinedIcon sx={facilityIcons} />
											<Typography sx={descriptionText}>Unit Rent: </Typography>
											<Typography sx={detailsText}>{unitRent}</Typography>
										</FlexCenter>
										<FlexCenter>
											<BedOutlinedIcon sx={facilityIcons} />
											<Typography sx={descriptionText}>Rent Currency: </Typography>
											<Typography sx={detailsText}>{rentCurrency}</Typography>
										</FlexCenter>
										<FlexCenter>
											<BedOutlinedIcon sx={facilityIcons} />
											<Typography sx={descriptionText}>Rent Cycle: </Typography>
											<Typography sx={detailsText}>{rentCycle}</Typography>
										</FlexCenter>
									</Stack>
								</Grid>
							</Grid>
						</Box>
					</Stack>
				</Grid>
			</Grid>
			<Grid sx={{ display: { xs: "none", sm: "block" } }} xs={12} sm={6}>
				<Card sx={{marginBottom: "30px"}}>
					<CardContent sx={{ padding: 0 }}>
						<FlexStart sx={{ padding: "16px" }}>
						{/* <LandLordAvatar /> */}
							<Box sx={{ marginLeft: "8px" }}>
								<Typography variant="body1" sx={{ fontSize: "17px" }}>
									Add Unit Images
								</Typography>
							</Box>
						</FlexStart>
						<Divider />
						<Stack spacing={3} sx={{ padding: "16px" }}>
							<ImageUploader files={files} setFiles={setFiles} maxFiles={3}/>
						</Stack>
					</CardContent>
				</Card>

				<Card >
					<CardContent sx={{ padding: 0 }}>
						<FlexStart sx={{ padding: "16px" }}>
						{/* <LandLordAvatar /> */}
							<Box sx={{ marginLeft: "8px" }}>
								<Typography variant="body1" sx={{ fontSize: "17px" }}>
									Terms and Conditions
								</Typography>
							</Box>
						</FlexStart>
						<Divider />
						<Stack spacing={3} sx={{ padding: "16px" }}>
							
						</Stack>
					</CardContent>
				</Card>
				{/* <Grid sx={{marginTop:"30px"}}>
					<Button variant="contained" endIcon={<SendIcon />} onClick={handleRentalUnitFormSubmit} sx={{padding:"15px"}}>Submit Unit Details</Button>
				</Grid> */}
			</Grid>
		</Grid>
			<FormDialog
				open={open}
				handleClose={handleClose}
				enableActions={true}
				content={
					<UnitDetailsEditForm
						unitName={unitName}
						handleChangeUnitName={handleChangeUnitName}
						unitType={unitType}
						handleChangeUnitType={handleChangeUnitType}
						bedrooms={bedrooms}
						handleChangeBedrooms={handleChangeBedrooms}
						bathrooms={bathrooms}
						handleChangeBathrooms={handleChangeBathrooms}
						dstv={dstv}
						handleChangeDstv={handleChangeDstv}
						fibre={fibre}
						handleChangeFibre={handleChangeFibre}
						funished={funished}
						handleChangeFurnished={handleChangeFurnished}
						insideBathroom={insideBathroom}
						handleChangeInsideBathroom={handleChangeInsideBathroom}
						insideKitchen={insideKitchen}
						handleChangeInsideKitchen={handleChangeInsideKitchen}
						store={store}
						handleChangeStore={handleChangeStore}
					/>
				}
				title={"Edit Unit Details"}
				// handleSubmit={handleRentalEditFormSubmit}
				buttonText="submit"
			/>

			<FormDialog
				open={openMeta}
				handleClose={handleCloseMeta}
				enableActions={true}
				content={
					<UnitMetaDataEditForm
						unitRent={unitRent}
						handleChangeUnitRent={handleChangeUnitRent}
						rentCycle={rentCycle}
						handleChangeRentCycle={handleChangeRentCycle}
					/>
				}
				title={"Edit Unit Meta Data Details"}
				handleSubmit={handleRentalUnitMetaDataEditFormSubmit}
				buttonText="submit"
			/>
    </Box>
  );
};

export default UnitDetails;