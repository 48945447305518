import * as React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, Grid, InputLabel, Select } from "@mui/material";

export default function CreateInvoiceForm(props) {
	return (
		<>
			<Grid container component="form" noValidate autoComplete="off" spacing={2} sx={{marginTop:"1px"}}>
				<Grid item xs={12} sm={6}>
					<TextField size="small" fullWidth label="Property Name" onChange={props.handleRentalNameChange} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth size="small">
						<InputLabel id="demo-simple-select-label">Property Location (City)</InputLabel>
						<Select
							label="Property Location (City)"
							labelId="rental-type"
							id="rental-type-id"
							value={props.rentalCity}
							onChange={props.handleRentalCityChange}
						>
							<MenuItem value="KAMPALA">Kampala</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField size="small" fullWidth label="Property Location (Parish & County)" onChange={props.handleRentalLocationChange} />
				</Grid>
			</Grid>
		</>
	);
}
