import * as React from "react";
import { Grid, TextField } from "@mui/material";

export default function TenantUpdateForm(props) {
	return (
		<Grid container component="form" noValidate autoComplete="off" spacing={2}>
			<Grid item xs={12} sm={12}>
				<TextField size="small" fullWidth label="Tenant Name"  />
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextField size="small" fullWidth label="Contact Info"  />
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextField size="small" fullWidth label="Property"  />
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextField size="small" fullWidth label="Unit Name"  />
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextField size="small" fullWidth label="Duration"  />
			</Grid>
		</Grid>
	);
}
