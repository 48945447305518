import * as React from "react";
import { Grid, TextField } from "@mui/material";

export default function TenantMessageForm(props) {
	return (
		<Grid container component="form" noValidate autoComplete="off" spacing={2}>
			<Grid item xs={12} sm={12}>
				<TextField size="large" multiline={true} minRows={5} fullWidth label="Type message here" />
			</Grid>
		</Grid>
	);
}
