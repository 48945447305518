import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TicketUpdateForm from "components/Forms/TicketUpdateForm";
import BorderColorIcon from "@mui/icons-material/BorderColor";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

export default function TicketUpdateModal({details}) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Button sx={{ color: "black", padding: "10px" }} onClick={handleClickOpen}>
				<BorderColorIcon sx={{fontSize:"16px", marginRight:"5px"}} /> EDIT TICKET
			</Button>
			<BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
				<DialogTitle sx={{ m: 0, p: 2, fontSize: "20px"}} id="customized-dialog-title">
					Update Ticket
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent sx={{ width: { xs: "256px", sm: "500px" } }} dividers>
					<TicketUpdateForm details={details} />
				</DialogContent>
			</BootstrapDialog>
		</React.Fragment>
	);
}
