import React from "react";

const formatDate = (date) => {
    const convertedDate = new Date(date);
    return convertedDate.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).split('/').reverse().join('-');
}

const formatDateString = (date) => {
    const convertedDate = new Date(date);
    return convertedDate.toDateString();
}

export {formatDate, formatDateString}