import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "scenes/loginPage";
import ProfilePage from "scenes/profilePage";
import Properties from "scenes/Properties/index";
import Tenants from "scenes/Tenants";
import Tickets from "scenes/Tickets";
import Payments from "scenes/Payments";
import Dashboard from "scenes/Dashboard";
import Units from "scenes/Properties/units";
import Settings from "scenes/Settings";
import Reports from "scenes/Reports";
import NotFound from "scenes/404/Notfound";
import PropertyDetails from "scenes/Properties/propertyDetails";
import UnitCreate from "scenes/Properties/unitCreate";
import UnitDetails from "scenes/Properties/unitDetails";
import LandlordPayments from "scenes/Payments/landlordPayments";
import Invoices from "scenes/Invoices/Invoices";
import Expenses from "scenes/Expenses/Expenses";
import TenantPayments from "scenes/Tenants/tenantPayments";

import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import Sidebar from "scenes/global/NewSidebar";
import Navbar from "scenes/navbar";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const [isSidebar, setIsSidebar] = useState(true);
  const isAuth = Boolean(useSelector((state) => state.token));
  // const isAuth = true;

  return (
			<div className="app">
				<BrowserRouter>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						{isAuth ? (
							<>
								<Navbar setIsSidebar={setIsSidebar} />
								<Box sx={{ display: "flex" }}>
									<Sidebar isSidebar={isSidebar} />
									<main className="content">
										<Box>
											<Routes>
												<Route path="/" element={<Dashboard />}/>
												<Route path="/tenants" element={ <Tenants /> }/>
												<Route path="/tickets" element={<Tickets />}/>
												<Route path="/properties" element={<Properties/>} />
												<Route path="/properties/details" element={<PropertyDetails/>} />
												<Route path="/units" element={<Units/>} />
												<Route path="/units/create" element={<UnitCreate/>} />
												<Route path="/units/details" element={<UnitDetails/>} />
												<Route path="/payments" element={ <Payments />} />
												<Route path="/invoices" element={ <Invoices />} />
												<Route path="/expenses" element={ <Expenses />} />
												<Route path="/payments/landlord" element={ <LandlordPayments />} />
												<Route path="/settings" element={<Settings/>} />
												<Route path="/reports" element={<Reports/>} />
												<Route path="/tenants/payments" element={<TenantPayments/>} />
												<Route path="/profile/:userId" element={<ProfilePage />} />
												<Route path="*" element={<NotFound/>} />
											</Routes>
										</Box>
									</main>
								</Box>
							</>
						) : (
							<main className="content">
								<Routes>
									<Route path="/" element={<Navigate to="/login" />} />
									<Route path="/login" element={<LoginPage />} />
								</Routes>
							</main>
						)}
					</ThemeProvider>
				</LocalizationProvider>
				</BrowserRouter>
			</div>
		);
}

export default App;
