import * as React from "react";
import { Stack, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { IoMdEyeOff } from "react-icons/io";
import { formatDate, formatDateString } from "utilities/date";
import { currencyFormatter } from "utilities/currencyFormatter";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#f1f1f5",
		color: "#000",
		fontSize: 15,
		fontWeight: 700,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 16,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: "white",
	},
	// // hide last border
	// "&:last-child td, &:last-child th": {
	// 	border: 0,
	// },
}));


function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

export default function LandlordPaymentsTable(props) {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [showDetails, setShowDetails] = React.useState(false);
	const [activeID, setActiveID] = React.useState(null);
	const location = useLocation();

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const toggleDetailsView = (rowId) => {
		setActiveID(rowId);
		setShowDetails(!showDetails);
	};

	return (
		<>
			<TableContainer sx={{ display: { xs: "none", md: "block" } }} component={Paper}>
				<Table sx={{ minWidth: 700 }} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Amount Paid</StyledTableCell>
							<StyledTableCell align="left">Month Paid For</StyledTableCell>
							<StyledTableCell align="left">Date Paid</StyledTableCell>
							<StyledTableCell align="left">Property Paid For</StyledTableCell>
							<StyledTableCell align="left">Paid By</StyledTableCell>
							<StyledTableCell align="left">Actions</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0 ? props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : props.rows).map((row) => (
							<StyledTableRow key={row.id}>
								<StyledTableCell component="th" scope="row">
									{currencyFormatter(parseInt(row.amount))}
								</StyledTableCell>
								<StyledTableCell align="left">{row.related_month.month} {row.related_month.year}</StyledTableCell>
								<StyledTableCell align="left">{row.date_paid}</StyledTableCell>
								<StyledTableCell align="left">{row.related_landlord_rental.rental_name}</StyledTableCell>
								<StyledTableCell align="left">{row.added_by.first_name} {row.added_by.last_name}</StyledTableCell>
								<StyledTableCell align="left"></StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
				<TablePagination
					component="div"
					colSpan={12}
					count={props.rows.length}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Rows per page" // Custom label for "Rows per page"
					sx={{
						// Styles for the TablePagination component itself
						"& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input.css-baf1rs-MuiInputBase-root-MuiTablePagination-select": {
							marginRight: "5px",
						},
						"& .MuiTablePagination-actions": {
							marginLeft: 0,
						},
						"& .MuiTablePagination-displayedRows": {
							fontSize: "1rem",
							margin: 0,
						},
						"& .MuiSelect-select.MuiInputBase-input": {
							fontSize: "1rem",
							marginTop: "10px",
						},
						"& .MuiTablePagination-selectLabel": {
							fontSize: "1rem",
						},
						"& .MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select": {
							marginTop: "4px",
						},
					}}
				/>
			</TableContainer>

			{/* Mobile */}
			{/* <TableContainer sx={{ display: { xs: "block", md: "none" } }} component={Paper}>
				<Table aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Amount Paid</StyledTableCell>
							<StyledTableCell align="left">Actions</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.isLoadingRentals ? (
							<StyledTableRow></StyledTableRow>
						) : (
							<>
								{(rowsPerPage > 0 ? props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : props.rows).map((row) => (
									<StyledTableRow key={row.id}>
										<StyledTableCell align="left">
											<span>
												{row.related_tenant.first_name} {row.related_tenant.last_name}
											</span>
											<Stack spacing={1} style={{ fontSize: "16px", marginTop: "7px" }}>
												{activeID === row.id && showDetails ? (
													<>
														<span>
															<strong>Amount:</strong> {currencyFormatter(parseInt(row.amount))}
														</span>
														<span>
															<strong>Rental Name:</strong> {row.related_rental_unit.related_rental.rental_name}
														</span>
														<span>
															<strong>Unit name:</strong> {row.related_rental_unit.unit_name}
														</span>
														<span>
															<strong>Due date:</strong> {row.related_rental_unit.due_date}
														</span>
													</>
												) : null}
											</Stack>
										</StyledTableCell>
										<StyledTableCell align="center">
											<span style={{ cursor: "pointer", display: "flex", alignItems: "center", fontSize: "15px" }} onClick={() => toggleDetailsView(row.id)}>
												See{" "}
												{activeID === row.id && showDetails ? (
													<>
														{" "}
														less <IoMdEyeOff style={{ marginLeft: "5px", marginTop: "1px" }} />{" "}
													</>
												) : (
													<>
														{" "}
														more <FaRegEye style={{ marginLeft: "5px", marginTop: "1px" }} />{" "}
													</>
												)}
											</span>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</>
						)}
					</TableBody>
				</Table>
				<TablePagination
					component="div"
					count={props.rows.length}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Rows" // Custom label for "Rows per page"
					sx={{
						// Styles for the TablePagination component itself
						"& .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input.css-baf1rs-MuiInputBase-root-MuiTablePagination-select": {
							marginRight: "5px",
						},
						"& .MuiTablePagination-actions": {
							marginLeft: 0,
						},
						"& .MuiTablePagination-displayedRows": {
							fontSize: "0.95rem",
							margin: 0,
						},
						"& .MuiSelect-select.MuiInputBase-input": {
							fontSize: "0.95rem",
							marginTop: "5px",
						},
						"& .MuiTablePagination-selectLabel": {
							fontSize: "0.95rem",
						},
						"& .MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select": {
							marginTop: "4px",
						},
					}}
				/>
			</TableContainer> */}
		</>
	);
}
