import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect(props) {

  return (
			<Box sx={{ minWidth: 120 }}>
				<FormControl fullWidth size="small">
					<InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id={props.id}
						value={props.value}
						label={props.label}
						onChange={props.handleChange}
					>
						{props.data.map((value, index) => {
							return (
								<MenuItem key={index} value={value.id}>
									{value.rental_name}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</Box>
		);
}