import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

export default function PropertyPopover({ popperId, openPopper, anchorEl, handleClosePopOver, handleClickOpen, navigate, activeRental, handleModalView, navigateUnits }) {
	const handleAction = (modalview) => {
		handleModalView(modalview);
		handleClickOpen();
	};
	return (
		<div style={{ zIndex: 20000 }}>
			<Popover
				id={popperId}
				open={openPopper}
				anchorEl={anchorEl}
				onClose={handleClosePopOver}
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			>
				<Stack sx={{ padding: "10px", alignContent: "left" }}>
					<Button sx={{ color: "black", padding: "10px" }} onClick={() => navigateUnits(activeRental)}>
						<RemoveRedEyeIcon sx={{ fontSize: "16px", marginRight: "5px" }} />
						View Units
					</Button>
					<Divider />
					<Button sx={{ color: "black", padding: "10px" }} onClick={() => navigate("/properties/details", { state: { id: activeRental } })}>
						View Property Details
					</Button>
					<Divider />
					{/* <Button sx={{ color: "black", padding: "10px" }} onClick={() => handleAction("edit")}>
						Edit Property
					</Button> */}
					<Divider />
					<Button sx={{ color: "red", padding: "10px" }} onClick={() => handleAction("delete")}>
						Delete Property
					</Button>
				</Stack>
			</Popover>
		</div>
	);
}
