import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Form from "./Form";

const LoginPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const smallMobileScreens = useMediaQuery("(min-width: 1150px)");
  return (
    <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      margin: "2rem",
      padding: "1rem 1rem",
      justifyContent: "center"
    }}
    >
      {smallMobileScreens && (
        <Box
          width={isNonMobileScreens ? "55%" : "50%"}
          p="2rem"
          m="2rem 0"
          borderRadius="1.5rem"
          // backgroundColor={theme.palette.background.alt}
          sx={{
            alignSelf: 'flex-start'
          }}
        >
          <img src="./assets/Frame.png" />
        </Box>
      )}
      
      <Box
        p="1rem"
        m="6rem 0"
        borderRadius="1.5rem"
        // backgroundColor={theme.palette.background.alt}
        sx={{
          alignSelf: 'flex-start'
        }}
      >
        <Typography fontWeight="500" variant="h3" sx={{ textAlign: "center", mb: "1.5rem" }}>
          Welcome to Rent Beta, Sign In
        </Typography>
        <Form />
      </Box>
    </Box>
  );
};

export default LoginPage;
