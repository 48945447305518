import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "theme";

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import DashboardIcon from '@mui/icons-material/Dashboard';
import FolderIcon from '@mui/icons-material/Folder';
import ReceiptIcon from '@mui/icons-material/Receipt';
import HouseIcon from '@mui/icons-material/House';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';

const Sidebar = () => {

  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const neutralLight = theme.palette.neutral.light;
  const dark = theme.palette.neutral.dark;
  const background = theme.palette.background.default;
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const universalMedium = theme.palette.universal.medium;

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: {primaryLight},
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#FEF0DE !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
        height: "100vh",
        width: "100%"
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 10px 0",
              color: universalMedium,
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={universalMedium}>
                  Menu
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)} style={{ color: universalMedium, }} >
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="40px">
              <MenuItem 
                icon={<DashboardIcon/>}
                style={{
                  margin: "10px 0 10px 0",
                  color: universalMedium,
                  fontSize: "20px"
                }}
                onClick={() => navigate("/home")}
              > Dashboard </MenuItem>
              <MenuItem
                icon={<FolderIcon/>}
                style={{
                  margin: "10px 0 10px 0",
                  color: universalMedium,
                  fontSize: "20px"
                }}
                onClick={() => navigate("/tickets")}
              > Tickets </MenuItem>
              <MenuItem
                icon={<ReceiptIcon/>}
                style={{
                  margin: "10px 0 10px 0",
                  color: universalMedium,
                  fontSize: "20px"
                }}
                onClick={() => navigate("/payments")}
              > 
                Payments 
              </MenuItem>
              <MenuItem
                icon={<HouseIcon/>}
                style={{
                  margin: "10px 0 10px 0",
                  color: universalMedium,
                  fontSize: "20px"
                }}
                onClick={() => navigate("/properties")}
              > 
                Properties
              </MenuItem>
              <MenuItem
                icon={<PeopleIcon/>}
                style={{
                  margin: "10px 0 10px 0",
                  color: universalMedium,
                  fontSize: "20px"
                }}
                onClick={() => navigate("/tenants")}
              > Tenants </MenuItem>
              <MenuItem
                icon={<SettingsIcon/>}
                style={{
                  margin: "10px 0 20px 0",
                  color: universalMedium,
                  fontSize: "20px"
                }}
              > Settings</MenuItem>
              
            </Box>
          )}

          
        
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
