import React from "react";
import { BorderedFlexRow } from "components/FlexBox/FlexBox";
import DialogContentText from "@mui/material/DialogContentText";
import WarningIcon from '@mui/icons-material/Warning';

export default function DeleteAccountView({text}) {
    return (
        <BorderedFlexRow>
            <WarningIcon sx={{ color:"#ed6b1a" }} />
            <DialogContentText sx={{fontSize:"16px", marginBottom: 2, color:"#ed6b1a" }}>{text}</DialogContentText>
        </BorderedFlexRow>
    )
}