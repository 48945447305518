import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { deepOrange } from "@mui/material/colors";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box } from "@mui/material";

export default function NavAvatar({name}) {
	function getFirstInitial(fullName) {
		const initial = fullName.charAt(0).toUpperCase();
		return initial;
	}

	return (
		<Stack direction="row" spacing={1} sx={{ alignItems: "center"}}>
			<Avatar sx={{ bgcolor: deepOrange[500], width: {xs:30, md:30}, height:{xs:30, md:30}, fontSize: { xs: "25px", md: "20px" } }}>{getFirstInitial(name)}</Avatar>
			<Box sx={{ display: "flex", color: { xs: "black", md: "white" }, fontSize: { xs: "20px", md: "18px" }, alignItems: "center", cursor: "pointer" }}>
				<span>{name}</span>
				<ArrowDropDownIcon sx={{ display: { xs: "none", md: "block" } }} />
			</Box>
		</Stack>
	);
}
