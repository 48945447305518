import * as React from "react";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";

export default function ChangePasswordForm({handleChangePassword, handleChangeConfirmPassword}) {
	return (
		<>
			<Grid container component="form" noValidate autoComplete="off" spacing={2} sx={{marginTop:"1px"}}>
				<Grid item xs={12} sm={12}>
					<TextField size="medium" fullWidth label="New Password" onChange={handleChangePassword} />
				</Grid>
                <Grid item xs={12} sm={12}>
					<TextField size="medium" fullWidth label="Confirm New Password" onChange={handleChangeConfirmPassword} />
				</Grid>
			</Grid>
		</>
	);
}
