import * as React from 'react';
import { Grid, Box } from "@mui/material";
import PaymentsTable from 'components/Tables/PaymentsTable';
import DummyTable from 'components/Tables/DummyTable';
import ProgressScale from 'components/ProgressScale';
import BasicSelect from 'components/select';
import FormDialog from 'components/modal';
import { useGetPaymentsData, useRentals } from 'services/hooks/rentals';

export default function Payments() {
    const [rental, setRental] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const {paymentsData, isLoadingPaymentsData} = useGetPaymentsData()
    const {rentals } = useRentals()

    const handleSelectChange = (event) => {
        setRental(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };
 

    return (
			<Box m="20px">
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{isLoadingPaymentsData && <ProgressScale />}
						<h1>Payments</h1>
						<Grid container spacing={2}>
							<Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
								<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter By Rental"} data={rentals} />
							</Grid>
							<Grid item xs={12} sm={5} md={8} sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "end", alignItems: "center" }}>
								
							</Grid>
							<Grid item sx={{ display: { xs: "block", sm: "none" } }} xs={12} sm={4}>
								<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter By Rental"} data={rentals} />
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12} sx={{ marginTop: 3 }}>
								{isLoadingPaymentsData ? <DummyTable /> : <PaymentsTable rows={paymentsData} />}
							</Grid>
						</Grid>
						<FormDialog open={open} handleClose={handleClose} />
					</Grid>
				</Grid>
			</Box>
		);
}