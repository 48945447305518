import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import HideSourceIcon from "@mui/icons-material/HideSource";


export default function TicketDelete({ details }) {
	return (
		<Stack spacing={3}>
			<Box sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
				<strong>&nbsp;Ticket:&nbsp;</strong>
				<Typography variant="h5">{details.title}</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
				<PersonIcon />
				<strong>&nbsp;Logged by:&nbsp;</strong>
				<Typography variant="h5">
					{details.related_tenant.first_name} {details.related_tenant.last_name}
				</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
				<PhoneIcon />
				<strong>&nbsp;Tenant Contact:&nbsp;</strong>
				<Typography variant="h5">0{details.related_tenant.phone_number}</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
				<EmailIcon />
				<strong>&nbsp;Tenant Email:&nbsp;</strong>
				<Typography variant="h5">{details.related_tenant.email}</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
				<ArticleIcon />
				<strong>&nbsp;Description:&nbsp;</strong>
				<Typography variant="h5">{details.description}</Typography>
			</Box>
		</Stack>
	);
}
