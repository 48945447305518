import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

export default function SettingsPill({ pillTitle, pillDescription, buttonText, buttonColor, buttonAction }) {
	return (
		<Box sx={{
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			marginY: "10px"
		}}>
			<Box>
				<Typography sx={{ fontWeight: 600 }} variant="h4" component="div">
					{pillTitle}
				</Typography>
				<Typography sx={{ fontWeight: 300 }} variant="h6" component="div">
					{pillDescription}
				</Typography>
			</Box>
			<Button color={buttonColor} variant="outlined" onClick={buttonAction}>
				{buttonText}
			</Button>
		</Box>
	);
}
