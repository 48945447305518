import * as React from "react";
import { Grid, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import BasicSelect from "components/select";
import { formatDate } from "utilities/date";
import { useGetLandlordInvoicesData, useGetMonthsData, useRentals } from "services/hooks/rentals";
import ProgressScale from "components/ProgressScale";
import DummyTable from "components/Tables/DummyTable";
import FormDialog from "components/modal";
import CreateInvoiceForm from "components/Forms/InvoiceForms/CreateInvoiceForm";
import LandlordInvoicesTable from "components/Tables/LandlordInvoicesTable";

export default function Invoices() {
	const [rental, setRental] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [details, setDetails] = React.useState("");
	const [modal, setModal] = React.useState("");
	const { invoicesData, isLoadingInvoicesData, errorLoadingInvoicesData } = useGetLandlordInvoicesData();
	const { rentals } = useRentals();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;

	const handleSelectChange = (event) => {
		setRental(event.target.value);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleModalView = (modalView) => {
		setModal(modalView);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleClickPopOver = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClosePopOver = () => {
		setAnchorEl(null);
	};

	console.log(modal);


	return (
		<Box m="20px">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{isLoadingInvoicesData && <ProgressScale />}
					<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<h1 className="pageHeader">Invoices</h1>
					</div>
					<Grid container spacing={2}>
						<Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
							<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter By Rental"} data={rentals} />
						</Grid>
                        <Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
							<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter By Month"} data={rentals} />
						</Grid>
                        <Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
                            <Button
                            className="add-button"
                            variant="contained"
                            endIcon={<AddIcon />}
                            >
                                Create Invoice
                            </Button>
                        </Grid>
						<Grid item xs={12} sm={5} md={8} sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "end", alignItems: "center" }}>
							{/* <Button className="add-button" variant="contained" onClick={handleClickOpen} endIcon={<AddIcon />}>
											Add Ticket
										</Button> */}
						</Grid>
						<Grid item sx={{ display: { xs: "block", sm: "none" } }} xs={12} sm={4}>
							<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter Rental"} data={rentals} />
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sx={{ marginTop: 3 }}>
							{isLoadingInvoicesData ? (
								<DummyTable />
							) : (
								<LandlordInvoicesTable
									rows={invoicesData}
									handleClickPopOver={handleClickPopOver}
									id={popperId}
									formatDate={formatDate}
									setDetails={setDetails}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<FormDialog
				open={open}
				handleClose={handleClose}
				title={"Add A New Invoice"}
				enableActions={true}
				buttonText="Create Invoice"
				content={
					<CreateInvoiceForm
						
					/>
				}
			/>
		</Box>
	);
}
